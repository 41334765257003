////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(37, 150, 190, 1);
}
.bg-fourthColor {
  background-color: rgba(253, 238, 5, 1);
}
.bg-fifthColor {
  background-color: rgba(253, 238, 5, 0.45);
}
.bg-sixthColor {
  background-color: rgba(189, 189, 189, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 255, 255, 1);
}
